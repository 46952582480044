<template>
  <div class="container">
    <pcPublicHeader />
    <div class="page-bg-item img-cover">
      <img src="../../assets/pc-images/website-bg.png" />
    </div>
    <div class="public-content">
      <div class="website-item">
        <div v-for="(item, index) in vipList" :key="index" class="item">
          <div class="vip-info">
            <div class="detail" :class="'level-' + item.Level">
              <div class="label">{{ item.Name }}</div>
              <div class="money-item"><span>{{ item.Money }}</span>元 丨 永久使用</div>
            </div>
            <div class="icon img-cover"> <img :src="item.Banner" /></div>
          </div>
          <div class="vip-detail">
            <div v-if="item.Level <= userInfo.FzLevel" class="btn" @click="handleShowWebsite">已创建分站，查看分站</div>
            <div v-else-if="userInfo.FzLevel" class="btn blue" @click="handleUpgradeWebsite(item.Level)">升级分站</div>
            <div v-else class="btn blue" @click="handleShowBuildPopup(item.Level)">创建分站</div>
            <div class="title-item">{{ item.Name }}权益</div>
            <div class="auth-list-item">
              <div v-for="(auth, authIndex) in authList" :key="authIndex"  class="auth-item">
                <div class="icon img-cover">
                  <img v-if="auth['Vip' + item.Level] == 1" src="../../assets/images/selected-icon.png" />
                  <img v-else src="../../assets/images/selected-not-icon.png" />
                </div>
                <div class="auth">{{ auth.Title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog class="pc-dialog" title="分站搭建" width="810px" :visible.sync="buildPopupData.show">
      <div class="popup-container input-popup-item">
        <div class="input-item">
          <div class="name">二级域名：</div>
          <div class="input gray">
            <input v-model="buildPopupData.from.url1" type="text" placeholder="请输入您想要的二级前缀可用字母数字" />
            <div class="random" @click="handleRandom">随机</div>
          </div>
        </div>
        <div class="input-item">
          <div class="name">域名选择：</div>
          <div class="input gray">
            <el-select style="width: 100%;" v-model="buildPopupData.from.url2" placeholder="请选择域名">
              <el-option v-for="(item, index) in urlList" :key="index" :label="item" :value="item" />
            </el-select>
          </div>
        </div>
        <div class="tips-item">可用字母，2-5字，不能有标点符号（尽量简短，便于推广宣传）</div>
        <div class="input-item">
          <div class="name">网站名称：</div>
          <div class="input gray">
            <input v-model="buildPopupData.from.SiteName" type="text" placeholder="请输入网站名称" />
          </div>
        </div>
        <div class="input-item">
          <div class="name">网站Logo：</div>
          <div class="logo-item">
            <van-uploader name="Photo" :preview-image="false" :deletable="false" :after-read="handleUploadLogo">
              <div v-if="buildPopupData.from.Logo" class="img img-cover"><img :src="buildPopupData.from.Logo" /></div>
              <div v-else class="add-img img-contain"><img src="../../assets/images/add-btn-icon.png" /></div>
            </van-uploader>
          </div>
        </div>
        <div class="btn-item">
          <div class="btn" @click="buildPopupData.show = false">取消</div>
          <div class="btn blue" @click="handleSubmitBuild">
            <van-loading v-if="buildPopupData.submiting" color="#ffffff" />
            <span v-else>确定</span>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog class="pc-dialog" title="分站管理" width="810px" :visible.sync="websitePopupData.show">
      <div class="popup-container website-popup">
        <div class="item">
          <div class="name">分站等级：</div>
          <div class="level" :class="'level-' + userInfo.FzLevel">{{ userInfo.FName }}</div>
        </div>
        <template v-if="websitePopupData.info.url && websitePopupData.info.url.length > 0">
          <div v-for="(item, index) in websitePopupData.info.url" :key="index" class="item">
            <div class="name">前端地址：</div>
            <div class="value blue">
              {{ item.Url }}
              <img class="copy" src="../../assets/pc-images/copy-blue-icon.png" @click="handleCopy(item.Url)" />
            </div>
          </div>
          <div class="item">
            <div class="name">后台地址：</div>
            <div class="value blue">
              {{ websitePopupData.info.url[0].Url }}/admin.html
              <img class="copy" src="../../assets/pc-images/copy-blue-icon.png" @click="handleCopy(websitePopupData.info.url[0].Url + '/admin.html')" />
            </div>
          </div>
        </template>
        <div class="item">
          <div class="name">管理员账号：</div>
          <div class="value">{{ websitePopupData.info.admin_user }}</div>
        </div>
        <div class="item">
          <div class="name">管理员密码：</div>
          <div class="value">****** <span class="btn" @click="handleShowResetPwd">重置</span></div>
        </div>
        <div class="item">
          <div class="name">网站名称：</div>
          <div class="value">{{ websitePopupData.info.site_name }}</div>
        </div>
        <div class="btn-item">
          <div class="btn" @click="websitePopupData.show = false">取消</div>
          <div class="btn blue" @click="websitePopupData.show = false">升级分站</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog class="pc-dialog" title="设置分站密码" width="580px" :visible.sync="resetFzPwdPopupData.show">
      <div class="popup-container input-popup-item">
        <div class="input-item">
          <div class="name">新密码：</div>
          <div class="input">
            <input v-model="resetFzPwdPopupData.Pwd" :type="resetFzPwdPopupData.showPwd ? 'text' : 'password'" />
            <div class="eye-item img-cover" @click="resetFzPwdPopupData.showPwd = !resetFzPwdPopupData.showPwd">
              <img v-if="resetFzPwdPopupData.showPwd" src="../../assets/pc-images/eye-icon.png" />
              <img v-else src="../../assets/pc-images/eye-close-icon.png" />
            </div>
          </div>
        </div>
        <div class="input-item">
          <div class="name">确认密码：</div>
          <div class="input">
            <input v-model="resetFzPwdPopupData.SurePwd" :type="resetFzPwdPopupData.showSurePwd ? 'text' : 'password'" />
            <div class="eye-item img-cover" @click="resetFzPwdPopupData.showSurePwd = !resetFzPwdPopupData.showSurePwd">
              <img v-if="resetFzPwdPopupData.showSurePwd" src="../../assets/pc-images/eye-icon.png" />
              <img v-else src="../../assets/pc-images/eye-close-icon.png" />
            </div>
          </div>
        </div>
        <div class="btn-item">
          <div class="btn" @click="resetFzPwdPopupData.show = false">取消</div>
          <div class="btn blue" @click="handleSubmitResetPwd">
            <van-loading v-if="buildPopupData.submiting" color="#ffffff" />
            <span v-else>保存</span>
          </div>
        </div>
      </div>
    </el-dialog>
    
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import pcPublicHeader from '@/components/pcPublicHeader'

export default {
  name: "Website",
  components: {
    pcPublicHeader
  },
  data() {
    return {
      authList: [],
      vipList: [],
      websitePopupData: {
        show: false,
        info: {}
      },
      urlList: [],
      buildPopupData: {
        show: false,
        submiting: false,
        from: {
          Level: '',
          url1: '',
          url2: '',
          SiteName: '',
          // Qq: '',
          // Wx: '',
          Logo: ''
        }
      },
      resetFzPwdPopupData: {
        show: false,
        showPwd: false,
        showSurePwd: false,
        submiting: false,
        Pwd: '',
        SurePwd: '',
      }
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  filters: {
    
  },
  mounted() {
    this.handleGetAuthList()
    this.handleGetVipList()
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    handleCopy(text) {
      this.$copyText(`${text}`).then(() => {
        this.$toast('复制成功')
      }).catch(() => {
        this.$toast('复制失败')
      })
    },
    async handleGetAuthList() {
      var res = await this.$API.substationsAuthList.post()
      this.authList = res
    },
    async handleGetVipList() {
      var res = await this.$API.vipMoney.post({
        Type: 2
      })
      if (res && res.length > 0) {
        this.vipList = res
      }
    },
    async handleGetUrlList() {
      var res = await this.$API.urlList.post()
      const list = []
      for (let i = 0; i < res.info.length; i++) {
        const item = res.info[i]
        list.push(item.MainUrl)
      }
      this.urlList = list
    },
    handleShowBuildPopup(level) {
      this.handleGetUrlList()
      this.buildPopupData = {
        show: true,
        submiting: false,
        from: {
          Level: level,
          url1: '',
          url2: '',
          SiteName: '',
          // Qq: '',
          // Wx: '',
          Logo: ''
        }
      }
    },
    handleRandom() {
      const strArr = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('')
      const count = Math.round(Math.random() * 3) + 2
      let str = ''
      for (let index = 0; index < count; index++) {
        str += strArr[Math.round(Math.random() * (strArr.length - 1))]
      }
      this.buildPopupData.from.url1 = str
    },
    async handleUploadLogo(file) {
      var res = await this.$API.upload.post(file[0])
      if (res.error == 0) {
        this.buildPopupData.from.Logo = res.info
      } else {
        this.$dialog.alert({
          type: 'danger',
          className: 'pc-dialog',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定'
        })
      }
    },
    async handleSubmitBuild() {
      const { buildPopupData } = this
      if (buildPopupData.submiting) {
        return
      }
      const { from } = buildPopupData
      if (!from.url1) {
        this.$toast('请输入二级域名')
        return
      }
      if (!from.url2) {
        this.$toast('请选择域名')
        return
      }
      from.Url = from.url1 + '.' + from.url2
      delete from.url1
      delete from.url2
      if (!from.SiteName) {
        this.$toast('请输入网站名称')
        return
      }
      // if (!from.Qq) {
      //   this.$toast('请输入QQ号码')
      //   return
      // }
      // if (!from.Wx) {
      //   this.$toast('请输入微信号')
      //   return
      // }
      if (!from.Logo) {
        this.$toast('请上传网站LOGO')
        return
      }
      this.buildPopupData.submiting = true
      const params = {
        ...from
      }
      params.SiteName = params.SiteName.replace(/抖音/g, '音乐')
      params.SiteName = params.SiteName.replace(/DY/g, '音乐')
      params.SiteName = params.SiteName.replace(/dy dou/g, '音乐')
      params.SiteName = params.SiteName.replace(/快手/g, '手手')
      params.SiteName = params.SiteName.replace(/KS/g, '手手')
      params.SiteName = params.SiteName.replace(/ks/g, '手手')
      params.SiteName = params.SiteName.replace(/代刷/g, '乐园')
      params.SiteName = params.SiteName.replace(/业务/g, '乐园')
      params.SiteName = params.SiteName.replace(/水军/g, '乐园')
      params.SiteName = params.SiteName.replace(/粉丝/g, '乐园')
      params.SiteName = params.SiteName.replace(/涨粉/g, '乐园')
      params.SiteName = params.SiteName.replace(/点赞/g, '乐园')
      params.SiteName = params.SiteName.replace(/播放/g, '乐园')
      params.SiteName = params.SiteName.replace(/人气/g, '乐园')
      params.SiteName = params.SiteName.replace(/热门/g, '乐园')
      var res = await this.$API.buySubstations.post(params)
      this.buildPopupData.submiting = false
      if (res.error == 0) {
        this.GetUserInfo()
        this.buildPopupData.show = false
        this.$toast.success(res.info)
      } else {
        this.$dialog.alert({
          type: 'danger',
          className: 'pc-dialog',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }

    },
    async handleShowWebsite() {
      var res = await this.$API.substationsInfo.post()
      this.websitePopupData = {
        show: true,
        info: res.info
      }
    },
    handleShowResetPwd() {
      this.resetFzPwdPopupData = {
        show: true,
        showPwd: false,
        showSurePwd: false,
        submiting: false,
        Pwd: '',
        SurePwd: '',
      }
    },
    async handleSubmitResetPwd() {
      const { resetFzPwdPopupData } = this
      if (resetFzPwdPopupData.submiting) {
        return
      }
      if (resetFzPwdPopupData.Pwd == '') {
        this.$toast('请输入新密码')
        return
      }
      if (resetFzPwdPopupData.Pwd.length < 6 || resetFzPwdPopupData.Pwd.length > 20) {
        this.$toast('请设置6-20位密码')
        return
      }
      if (resetFzPwdPopupData.SurePwd == '') {
        this.$toast('请再次输入新密码')
        return
      }
      if (resetFzPwdPopupData.SurePwd.length < 6 || resetFzPwdPopupData.SurePwd.length > 20) {
        this.$toast('请设置6-20位密码')
        return
      }
      if (resetFzPwdPopupData.Pwd != resetFzPwdPopupData.SurePwd) {
        this.$toast('两次输入密码不一致')
        return
      }
      this.resetFzPwdPopupData.submiting = true
      var res = await this.$API.editSubstationsPwd.post({
        Password: resetFzPwdPopupData.Pwd,
      })
      this.resetFzPwdPopupData.submiting = false
      if (res.error == 0) {
        this.$toast.success(res.info)
        this.resetFzPwdPopupData.show = false
        this.GetUserInfo()
        return true
      } else {
        this.$dialog.alert({
          type: 'danger',
          className: 'pc-dialog',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
        return false
      }
    },
    handleUpgradeWebsite(level) {
      this.$dialog.confirm({
        type: 'info',
        className: 'pc-dialog',
        title: '升级分站',
        message: '确定要升级分站吗？',
        cancelButtonText: '取消',
        confirmButtonText: '确认',
      }).then(async () => {
        var res = await this.$API.buySubstations.post({
          Level: level
        })
        if (res.error == 0) {
          this.GetUserInfo()
          this.$toast.success(res.info)
        } else {
          this.$dialog.alert({
            type: 'danger',
            className: 'pc-dialog',
            title: '提示',
            message: res.info,
            confirmButtonText: '确定',
          })
        }
      }).catch(() => {
      })
    }
  }
};
</script>

<style lang="less" scoped>
.page-bg-item {
  width: 100%;
  height: 300px;
  margin-bottom: -106px;
}
.website-item {
  display: flex;
  justify-content: center;
  padding-bottom: 22px;
  .item {
    width: 320px;
    margin: 20px 20px 0;
    border-radius: 12px;
    box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, .16);
    .vip-info {
      position: relative;
      margin-bottom: -32px;
      .icon {
        width: 100px;
        height: 110px;
        position: absolute;
        top: -20px;
        right: 0;
      }
      .detail {
        position: relative;
        width: 320px;
        height: 140px;
        border-radius: 16px;
        .label {
          width: 77px;
          height: 23px;
          text-align: center;
          line-height: 23px;
          border-radius: 16px 0;
          font-size: 10px;
          color: #fff;
        }
        .money-item {
          margin-top: 30px;
          font-size: 12px;
          span {
            font-size: 32px;
            font-weight: bold;
            margin: 0 10px 0 12px;
          }
        }
      }
    }
    .vip-detail {
      position: relative;
      border-radius: 12px;
      background-color: #fff;
      padding: 16px 20px;
      .btn {
        cursor: pointer;
        width: 100%;
        height: 36px;
        border-radius: 4px;
        background-color: #9fafc1;
        line-height: 36px;
        text-align: center;
        color: #fff;
        font-weight: bold;
        margin-bottom: 12px;
        &.blue {
          background: linear-gradient( 90deg, #7683ff 0%, #6a5ffe 100%);
        }
      }
      .title-item {
        line-height: 24px;
        font-size: 16px;
        color: #242941;
        font-weight: bold;
      }
      .auth-list-item {
        .auth-item {
          display: flex;
          align-items: center;
          padding: 6px 0;
          .icon {
            width: 18px;
            height: 18px;
            margin-right: 6px;
          }
          .auth {
            color: #242941;
          }
        }
      }
    }
  }
}
.website-popup {
  padding: 20px 0;
  .item {
    padding: 0 30px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    .name {
      width: 96px;
      color: #333;
    }
    .level {
      padding: 0 10px;
      line-height: 25px;
      border-radius: 2px;
    }
    .value {
      display: flex;
      align-items: center;
      &.blue {
        color: #6a5ffe;
      }
      .copy {
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin-left: 12px;
      }
      .btn {
        cursor: pointer;
        margin-left: 12px;
        color: #6a5ffe;
      }
    }
  }
}
.input-popup-item {
  padding: 20px 0;
  .input-item {
    padding: 0 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .name {
      width: 96px;
    }
    .input {
      &.gray {
        background-color: #f5f5f5;
      }
      flex: 1;
      padding: 0 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        flex: 1;
      }
      .eye-item {
        cursor: pointer;
        margin-left: 20px;
        width: 20px;
        height: 20px;
      }
      .random {
        cursor: pointer;
        margin-left: 20px;
        margin-right: -10px;
        width: 52px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        border: 1px solid #6a5ffe;
        border-radius: 4px;
        background-color: rgba(106, 95, 254, .16);
        color: #6a5ffe;
      }
      /deep/.el-input__inner {
        background-color: transparent;
        border: none;
        padding: 0;
      }
    }
    .logo-item {
      width: 100px;
      height: 100px;
      border: 1px solid #ccc;
      border-radius: 6px;
      overflow: hidden;
      /deep/.van-uploader__wrapper {
        margin: 0;
      }
      .add-img {
        padding: 30px;
        width: 40px;
        height: 40px;
      }
      .img {
        width: 100px;
        height: 100px;
      }
    }
  }
  .tips-item {
    padding-right: 30px;
    text-align: right;
    margin-top: -8px;
    line-height: 21px;
    color: #fe346e;
    margin-bottom: 12px;
  }
}
.popup-container {
  .btn-item {
    width: 100%;
    border-top: 1px solid #f3f5f8;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 190px;
      height: 48px;
      margin: 0 20px;
      font-weight: bold;
      box-sizing: border-box;
      border-radius: 6px;
      border: 1px solid #9fafc1;
      color: #71757d;
      &.blue {
        color: #fff;
        border-width: 0;
        background: linear-gradient( 90deg, #7683FF 0%, #6A5FFE 100%);
      }
    }
  }
}
.level-50 {
  color: #3f3f3f !important;
  background: linear-gradient( 90deg, #ffffff 0%, #cbcbcb 100%);
  .label {
    background: linear-gradient( 121deg, #777678 0%, #3c3b3c 100%);
  }
}
.level-51 {
  color: #372506 !important;
  background: linear-gradient( 134deg, #fffdf8 0%, #ffc768 75%, #fff3e4 100%);
  .label {
    background-color: #f0c47f;
  }
}
.level-52 {
  color: #ebd29a !important;
  background: linear-gradient( 134deg, #221d11 0%, #0f0b08 75%, #403723 100%);
  .label {
    background: linear-gradient( 90deg, #94713b 0%, #c4a76a 100%);
  }
}
</style>
